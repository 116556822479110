import type { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { createContext } from 'react';

export interface FeaturedWidgetInstance {
  /**
   * The component id for the featured widget
   */
  componentId:
    | EndUserComponent.FEATURED_CONTENT_WIDGET
    | EndUserComponent.FEATURED_PLACES_WIDGET
    | EndUserComponent.FEATURED_IDEA_STATUSES_WIDGET
    | EndUserComponent.FEATURED_GUIDE_WIDGET;
  /**
   * The instance id of the featured widget
   */
  instanceId: string;
  /**
   * Ids of any associated items added to the widget during the page editor session
   */
  featuredItemIds: Array<string>;
  /**
   * The node id associated with the featured widget
   */
  coreNodeId: string;
}

export interface FeaturedWidgetContextInterface {
  /**
   * An array
   */
  featuredWidgetInstances: Array<FeaturedWidgetInstance>;
}

/**
 * Holds the featured widgets, and their associated content, for a give page, provided that they were added or updated
 * during the current page editor session
 *
 * Used to differentiate which API endpoints to use while displaying the widget content during a session
 */
const FeaturedWidgetContext = createContext<FeaturedWidgetContextInterface>({
  featuredWidgetInstances: []
});

export default FeaturedWidgetContext;
