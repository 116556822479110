import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/icons';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './FeaturedWidgetPlaceholder.module.pcss';

interface Props {
  /**
   * The text used in the button
   */
  buttonText: string;
  /**
   * Callback function invoked when the button is clicked
   */
  onClick: () => void;
}

/**
 * Renders the empty state for the featured content and featured places widgets
 *
 * @author Luisina Santos
 */
const FeaturedWidgetPlaceholder: React.FC<React.PropsWithChildren<Props>> = ({
  buttonText,
  onClick
}) => {
  const cx = useClassNameMapper(localStyles);

  return (
    <div data-testid="FeaturedWidgetPlaceholder" className={cx('lia-placeholder-wrapper')}>
      <Button
        className={cx('lia-placeholder-btn')}
        testId="FeaturedWidgetPlaceholder.Button"
        variant={ButtonVariant.NO_VARIANT}
        onClick={onClick}
      >
        <Icon
          icon={Icons.EditIcon}
          size={IconSize.PX_14}
          color={IconColor.GRAY_900}
          className={cx('lia-g-mr-10')}
        />
        <span className={cx('lia-placeholder-span')}>{buttonText}</span>
      </Button>
    </div>
  );
};

export default FeaturedWidgetPlaceholder;
